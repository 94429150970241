.cube {
    width: 150px;
    height: 150px;
    border-radius: 25px;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    cursor: pointer;
    margin:2%;
    background:#ffffff96;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  
  .cube::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
    z-index: 1;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100% ;
    z-index: 2;
  }
  
  .icon {
    width:50% ;
 display: flex;
 margin:2% auto;
    margin-bottom: 10px;
  }
  
  .description {
    text-align: center;
    font-size: 18px;
    font-family: "AssistantR";
    color: #333;
    margin: 0;
  }