
.button {
  padding: 1rem 2rem;
  border-radius: .5rem;
  border: none;
  font-size: 1rem;
  width: 200px;
  margin: 3% auto;
  justify-content: center;
  font-weight: 400;
  color: #333; /* צבע טקסט כהה יותר לאפקט קונטרסט */
  text-align: center;
  backdrop-filter: blur(10px);
  cursor: pointer;
  direction: rtl;
  position: relative;
  font-family: "Assistant";
  background: linear-gradient(to right, #a1817a, #AB7D73);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.button::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: .5rem;
      background: linear-gradient(to right, #AB7D73, #f8c8be);
  box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.1);
  z-index: -1;
}

.button::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: linear-gradient(to right, #AB7D73, #ffd9d1);
  box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: .5rem;
  opacity: 0;
  z-index: -1;
  transition: all .3s ease-in;
}

.button:hover::after {
  opacity: 1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}