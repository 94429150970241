.cube {
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 2% auto;
  padding: 15px;
  /* אפקט זכוכית */
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  /* צל עדין חיצוני ופנימי יוקרתי */
  box-shadow: 0 0 8px 2px rgba(249, 200, 165, 0.3),
              inset 0 10px 40px rgba(255, 255, 255, 0.9),
              inset 0 -15px 40px rgba(0, 0, 0, 0.05),
              inset -12px 0 45px rgba(255, 255, 255, 0.6),
              inset 12px 0 45px rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.cube:hover {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 0 10px 3px rgba(249, 200, 165, 0.4),
              inset 0 15px 45px rgba(255, 255, 255, 0.95),
              inset 0 -20px 45px rgba(0, 0, 0, 0.06),
              inset -15px 0 50px rgba(255, 255, 255, 0.7),
              inset 15px 0 50px rgba(255, 255, 255, 0.7);
  transform: translateY(-5px);
}

@keyframes shadowPulse {
  0%, 100% {
      box-shadow: 0 0 8px 2px rgba(249, 200, 165, 0.3),
                  inset 0 10px 40px rgba(255, 255, 255, 0.9),
                  inset 0 -15px 40px rgba(0, 0, 0, 0.05),
                  inset -12px 0 45px rgba(255, 255, 255, 0.6),
                  inset 12px 0 45px rgba(255, 255, 255, 0.6);
  }
  50% {
      box-shadow: 0 0 10px 3px rgba(249, 200, 165, 0.4),
                  inset 0 15px 45px rgba(255, 255, 255, 0.95),
                  inset 0 -20px 45px rgba(0, 0, 0, 0.06),
                  inset -15px 0 50px rgba(255, 255, 255, 0.7),
                  inset 15px 0 50px rgba(255, 255, 255, 0.7);
  }
}

/* הוספת אפקט נוסף לחיזוק תחושת הבועה */
.cube::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.1), transparent 70%);
  pointer-events: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  position: relative;
  z-index: 1;
}

.title {
  font-size: 1.5rem;
  font-family: "Assistant";
  text-align: center;
  margin: 3% auto;
  direction: rtl;
  width: 100%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  color: rgb(0, 0, 0);
  position: relative;
}

.icon {
  display: flex;
  justify-content: flex-end;
  width: 30%;
  margin: 0 auto;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2));
}

.description {
  font-size: 1.1rem;
  font-family: "AssistantR";
  text-align: center;
  margin: 2% auto;
  direction: rtl;
  width: 100%;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  color: rgb(0, 0, 0);
  position: relative;
}

@media only screen and (max-width: 350px) {
  .cube {
      width: 260px;
  }
  .title {
      font-size: 1.4rem;
  }
  .description {
      font-size: 1rem;
  }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
  .cube {
      width: 300px;
  }
  .title {
      font-size: 1.4rem;
  }
  .description {
      font-size: 1.05rem;
  }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
  .cube {
      width: 370px;
  }
  .title {
      font-size: 1.8rem;
  }
  .description {
      font-size: 1.15rem;
  }
}